<template>
  <div class="menuManage">
    <div class="menuManage_form">
      <el-form :inline="true" size="small" ref="searchForm" :model="searchForm">
        <el-form-item label="菜單名稱">
          <el-input
            v-model="searchForm.menu_name"
            placeholder="請輸入菜單名稱"
          ></el-input>
        </el-form-item>
        <el-form-item label="菜單模塊">
          <el-select v-model="searchForm.mold_type" @change="getMenuList">
            <el-option label="總後台" value="1"></el-option>
            <el-option label="店鋪後台" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getMenuList">搜索</el-button>
          <el-button type="success" @click="addMenu">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table_box">
      <el-table
        v-loading="tableLoading"
        :data="menulist"
        :border="true"
        row-key="menu_id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        class="table"
      >
        <el-table-column
          prop="menu_name"
          label="菜單名稱"
          width="160"
        ></el-table-column>
        <el-table-column prop="icon" label="圖標" align="center" width="100">
          <template slot-scope="scope">
            <svg-icon :icon-class="scope.row.icon" />
          </template>
        </el-table-column>
        <el-table-column
          prop="sort_num"
          label="排序"
          width="60"
        ></el-table-column>
        <el-table-column prop="perms" label="權限標識"></el-table-column>
        <el-table-column
          prop="component"
          label="組件路徑"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="status" label="狀態" width="80">
          <template slot-scope="scope">
            <el-tag
              size="small"
              :type="scope.row.status == 1 ? 'success' : 'danger'"
              >{{ scope.row.status == 1 ? "正常" : "停用" }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="230"
        >
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="text"
              @click="editMenu(scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-plus"
              type="text"
              @click="addMenu(scope.row)"
            >
              新增
            </el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDel(scope.row)"
            >
              刪除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 添加菜單 -->
    <el-dialog
      :title="menutitle + '菜單'"
      :visible.sync="menuVisible"
      width="600px"
    >
      <el-form
        ref="menuForm"
        :model="menuForm"
        :rules="menuFormRules"
        label-width="100px"
        size="small"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="上級菜單">
              <treeselect
                v-model="menuForm.parent_id"
                :options="menuOptions"
                :normalizer="normalizer"
                :show-count="true"
                placeholder="選擇上級菜單"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜單模塊" prop="mold_type">
              <el-radio-group v-model="menuForm.mold_type">
                <el-radio :label="1">總後台</el-radio>
                <el-radio :label="2">店鋪後台</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="菜單類型" prop="menu_type">
              <el-radio-group v-model="menuForm.menu_type">
                <el-radio label="M">目錄</el-radio>
                <el-radio label="C">菜單</el-radio>
                <el-radio label="F">按鈕</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24" v-if="menuForm.menu_type != 'F'">
            <el-form-item label="菜單圖標" prop="icon">
              <el-popover placement="bottom-start" width="460" trigger="click">
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input
                  slot="reference"
                  v-model="menuForm.icon"
                  placeholder="點擊選擇圖標"
                  readonly
                >
                  <svg-icon
                    v-if="menuForm.icon"
                    slot="prefix"
                    :icon-class="menuForm.icon"
                    class="el-input__icon"
                    style="height: 32px; width: 16px"
                  />
                  <i
                    v-else
                    slot="prefix"
                    class="el-icon-search el-input__icon"
                  />
                </el-input>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              v-if="menuForm.menu_type == 'F'"
              label="按鈕名稱"
              prop="menu_name"
            >
              <el-input
                v-model="menuForm.menu_name"
                placeholder="請輸入按鈕名稱"
              />
            </el-form-item>
            <el-form-item v-else label="菜單名稱" prop="menu_name">
              <el-input
                v-model="menuForm.menu_name"
                placeholder="請輸入菜單名稱"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="顯示排序" prop="sort_num">
              <el-input-number
                v-model="menuForm.sort_num"
                controls-position="right"
                :min="0"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="menuForm.menu_type != 'F'">
            <el-form-item prop="path">
              <span slot="label"> 路由地址 </span>
              <el-input v-model="menuForm.path" placeholder="請輸入路由地址" />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="menuForm.menu_type == 'C'">
            <el-form-item prop="component">
              <span slot="label">
                <el-tooltip
                  content="訪問的組件路徑，如：`system/user/index`，默認在`views`目錄下"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                組件路徑
              </span>
              <el-input
                v-model="menuForm.component"
                placeholder="請輸入組件路徑"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="menuForm.menu_type != 'M'">
            <el-form-item>
              <el-input
                v-model="menuForm.perms"
                placeholder="請輸入權限標識"
                maxlength="100"
              />
              <span slot="label">
                <el-tooltip
                  content="控制器中定義的權限字符，如：@PreAuthorize(`@ss.hasPermi('system:user:list')`)"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                權限字符
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="menuForm.menu_type == 'C'">
            <el-form-item>
              <el-input
                v-model="menuForm.query"
                placeholder="請輸入路由參數"
                maxlength="255"
              />
              <span slot="label">
                <el-tooltip
                  content='訪問路由的默認傳遞參數，如：`{"id": 1, "name": "ry"}`'
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                路由參數
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="menuForm.menu_type != 'F'">
            <el-form-item>
              <span slot="label">
                <el-tooltip
                  content="選擇停用則路由將不會出現在側邊欄，也不能被訪問"
                  placement="top"
                >
                  <i class="el-icon-question"></i>
                </el-tooltip>
                菜單狀態
              </span>
              <el-radio-group v-model="menuForm.status">
                <el-radio
                  v-for="(item, index) in statusList"
                  :key="index"
                  :label="item.value"
                  >{{ item.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="menuSubmit"
          >提交</el-button
        >
        <el-button size="small" @click="menuReset">重置</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import IconSelect from "../pages/iconSelect";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: "menuManage",
  components: {
    IconSelect,
    Treeselect,
  },
  data() {
    return {
      tableLoading: false,
      searchForm: {
        menu_name: "",
        mold_type: "1",
      },
      page: 1,
      pageSize: 99999,
      total: 0,
      menulist: [],
      statusList: [
        { label: "正常", value: 1 },
        { label: "停用", value: 2 },
      ],
      // 添加/修改
      menuOptions: [],
      menutitle: "添加",
      menuVisible: false,
      menuForm: {},
      // 表單校驗
      menuFormRules: {
        menu_name: [
          { required: true, message: "菜單名稱不能為空", trigger: "blur" },
        ],
        sort_num: [
          { required: true, message: "菜單順序不能為空", trigger: "blur" },
        ],
        path: [
          { required: true, message: "路由地址不能為空", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getMenuList();
    this.getMenuOption();
  },
  methods: {
    // 獲取列表
    getMenuList() {
      this.tableLoading = true;
      this.$api
        .getMenu({
          page: this.page,
          pageSize: this.pageSize,
          menu_name: this.searchForm.menu_name,
          mold_type: Number(this.searchForm.mold_type),
        })
        .then((res) => {
          this.tableLoading = false;
          if (res.code == 200) {
            this.menulist = res.data.list;
            this.total = res.data.count;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    // 選擇圖標
    selected(name) {
      console.log(name);
      this.menuForm.icon = name;
    },
    // 獲取菜單總列表
    async getMenuOption() {
      const res = await this.$api.getMenu();
      if (res.code == 200) {
        this.menuOptions = res.data.list;
        return this.menuOptions;
      } else {
        this.menuOptions = [];
        this.$message.error("菜單列表獲取失敗");
      }
    },
    /** 轉換菜單數據結構 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.menu_id,
        label: node.menu_name,
        children: node.children,
      };
    },
    /** 查詢菜單下拉樹結構 */
    getTreeselect() {
      this.getMenuOption().then((response) => {
        this.menuOptions = [];
        const menu = { menu_id: 0, menu_name: "頂級菜單", children: [] };
        menu.children = response;
        this.menuOptions.push(menu);
      });
    },
    //  重置
    menuReset() {
      this.menuForm = {
        parent_id: 0,
        menu_name: "",
        sort_num: "",
        mold_type: 1,
        path: "",
        component: "",
        query: "",
        menu_type: "M",
        status: 1,
        perms: "",
        icon: "",
      };
    },
    // 添加
    addMenu(row) {
      this.menuReset();
      this.getTreeselect();
      console.log(row);
      if (row != null && row.menu_id) {
        console.log(row);
        this.menuForm.parent_id = row.menu_id;
        this.menuForm.mold_type = row.mold_type;
      } else {
        this.menuForm.parent_id = 0;
      }
      this.menuVisible = true;
      this.menutitle = "添加";
    },

    // 編輯
    editMenu(row) {
      this.menuReset();
      this.getTreeselect();
      this.menutitle = "編輯";
      this.menuVisible = true;
      this.$data.menuForm = JSON.parse(JSON.stringify(row));
    },
    // 提交
    menuSubmit() {
      this.$refs["menuForm"].validate((valid) => {
        if (valid) {
          const loading = this.$loading("正在提交...");
          if (
            this.menuForm.menu_id != undefined &&
            this.menuForm.menu_id != null
          ) {
            this.$api.editMenu(this.menuForm).then((res) => {
              loading.close();
              if (res.code == 200) {
                this.$message.success("修改成功");
                this.menuVisible = false;
                this.getMenuList();
              }
            });
          } else {
            this.$api.addMenu(this.menuForm).then((res) => {
              console.log(res);
              if (res.code != 200) {
                this.$message.error(res.msg);
              } else {
                this.$message.success("添加成功");
                this.menuVisible = false;
                this.getMenuList();
                window.location.reload();
              }
            });
          }
        }
      });
    },
    // 刪除
    handleDel(row) {
      this.$confirm(
        "此操作將永久刪除名稱為" + row.menu_name + "的數據, 是否繼續?",
        "提示",
        {
          confirmButtonText: "確定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const delLoad = this.$loading("正在刪除..");
          this.$api.delMenu({ menu_id: row.menu_id }).then((res) => {
            console.log(res);
            delLoad.close();
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "刪除成功!",
              });
              window.location.reload();
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/user/menuManage.scss";
</style>
